export class MapStyle {
    /**
     * MapLibre JS Fill with interpolation between values.
     * @param {string} fillProperty Geo JSON property to use for fill.
     * @param {Array} colours Array of colour values
     * @param {number} min lowest value
     * @param {number} max max value
     */
    static fillInterpolate = (base) => (colours, opacity) => (min, max) => (fillProperty) => {
        const step = (max - min) / colours.length;
        const colList = colours.reduce((acc, next, idx) => [...acc, min + (idx * step), next], []);

        return {
            ...base,
            type: "fill",
            paint: {
                "fill-color": [
                    "interpolate",
                    ['linear'],
                    ['get', fillProperty],
                    ...colList
                ],
                "fill-opacity": opacity
            }
        };
    }
}