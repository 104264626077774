

/**
 * Returns a function that effectively rate-limits calls (used to e.g. keep interactive search sane.)
 * @param {*} callback callback to run at end of timeout.
 * @param {*} delay_ms Delay between events
 * @returns 
 */
export function debounce(callback, delay_ms) {
    let timeout;
    return () => {
        clearTimeout(timeout);
        timeout = setTimeout(callback, delay_ms);
    }
}