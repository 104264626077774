/*
 * Module for building an interactive search.
 */

import { debounce } from './utils-debounce.js'
import { replaceStringWithProperties } from './utils-object.js';


/**
 * Adds interactive search to the specified DOM objects
 * @param {Array} items Array of objects that will be searched
 * @param {String} searchProperty Name of property to search on - this should be present on all items[]
 * @param {String} itemTemplateSelector DOM selector string for the item template to use for each search result. ${propName} will be replaces by item[propName]
 * @param {String} collapsableSectionSelector DOM selector string for the collapsing "<details>" section that will be opened (.open = true) when a valid search is performed.
 * @param {String} searchBoxSelector Query selector for the input search box.
 * @param {String} resultsContainerSelector Query selector for the container for search results.
 * @param {number} initialDisplayCount Number of items to initially display. Useful for larger lists.
 */
export function buildPropertySearch(items, searchProperty, itemTemplateSelector, collapsableSectionSelector, searchBoxSelector, resultsContainerSelector, initialDisplayCount) {
        const itemTemplate = document.querySelector(itemTemplateSelector).innerHTML;
        const itemDetailsSection = document.querySelector(collapsableSectionSelector);
        const itemSearch = document.querySelector(searchBoxSelector);
        const itemContainer = document.querySelector(resultsContainerSelector);
        
        // Helper funcs for modifying DOM and finding search results.
        // These are locally scoped (for now) as the depend on the DOM objects and function params above.
        
        //Determines if a given input string is valid for search
        const isValidSearch = (str) => str && str.length >= 3
        //Gets HTML for a given item using the template above.
        const getItemFragment = (item) => replaceStringWithProperties(itemTemplate, item);
        //Finds matching items. If search input invalid show top n items based on source order (typically most recent first) instead.
        const findItems = (search) => {
            if (isValidSearch(search)) return items.filter(a => a[searchProperty].toLowerCase().includes(search.toLowerCase()))
            else return items.slice(0, initialDisplayCount);
        }
        //Adds found items to DOM.
        const loadItems = () => itemContainer.innerHTML = findItems(itemSearch.value).map(getItemFragment).join("");

        //Great - all done - set our event listener and load the initial list.
        itemSearch.addEventListener("keyup", debounce(() => {
            loadItems();
            if (itemDetailsSection && !itemDetailsSection.open && isValidSearch(itemSearch.value)) itemDetailsSection.open = true;
        }, 150));
        loadItems();
}