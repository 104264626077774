/**
 * Basic map instance with layer / pop-up support. Really just a thin wrapper around MapLibreJS.
 */
export class GeoJsonMap {
    #Map = null;
    #PopupHandlers = {}; // Map of layer id -> htmlGenerators, see setLayerPopup below.

    get #SymbolLayerId() {
        return this.#Map.getStyle().layers.find(l => l.type === 'symbol').id;
    }

    constructor(containerId, style, centre = [0, 0], zoom = 0) {
        //RTL text support
        maplibregl.setRTLTextPlugin('https://cdn.maptiler.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js');
        //Instantiate the map
        this.#Map = new maplibregl.Map({
            container: containerId,
            style: style,
            center: centre,
            zoom: zoom
        });
    }

    /**
     * Adds a new source to the map.
     * @param {*} sourceId New source ID
     * @param {*} type Type, e.g. GeoJSON
     * @param {*} sourceData source data
     */
    addSource(sourceId, type, sourceData) {
        this.#Map.on('load', () => {
            this.#Map.addSource(sourceId, { type: type, data: sourceData });
        });
        return this;
    }

    /**
     * Gets a source from the map for e.g. reloading, changing data, etc.
     */
    getSource(sourceId) {
        return this.#Map.getSource(sourceId)
    }

    /**
     * Adds a later to the map. If propSelector is 
     * @param {*} layerId name of the id property
     * @param {*} layer layer data.
     */
    addLayer(layerId, layer, addBefore=true) {
        this.#Map.on('load', () => {
            //All done - add the layer.
            if (addBefore) {
                this.#Map.addLayer({ ...layer, id: layerId }, this.#SymbolLayerId);
            }
            else {
                this.#Map.addLayer({ ...layer, id: layerId });   
            }
            
        });
        return this;
    }

    addImage(...args) {
        this.#Map.addImage(...args)
    }

    /**
     * Load image with callback
     * @param  {...any} args 
     */
    loadImage(...args) {
        this.#Map.loadImage(...args)
    }

    /**
     * Flies to a given location.
     * @param  {...any} args 
     */
    flyTo(...args) {
        this.#Map.flyTo(...args)
    }

    /**
     * Pans/zooms to a location
     * @param  {...any} args 
     */
    fitBounds(lngLat, options) {
        this.#Map.fitBounds(lngLat, options)
    }

    /**
     * Adds a popup handler to the map. propSelector should return the names of the link property, name property (heading) and text property within the GeoJSON.
     * @param {*} layerId 
     * @param {*} htmlGenerator Function of type ({object}) => string that returns the HTML for the popup. Takes property object as input.
     */
    setLayerPopup(layerId, htmlGenerator) {
        if (!this.#PopupHandlers.hasOwnProperty(layerId)) {
            //This layer hasn't been registered before - configure handlers.
            this.#Map.on('mouseenter', layerId, () => this.#Map.getCanvas().style.cursor = 'pointer');
            this.#Map.on('mouseleave', layerId, () => this.#Map.getCanvas().style.cursor = '');

            this.#Map.on('click', layerId, (e) => {
                const props = e.features[0].properties;

                new maplibregl.Popup()
                    .setLngLat(e.lngLat)
                    .setHTML(this.#PopupHandlers[layerId](props))
                    .addTo(this.#Map);
            });
        }
        //Always override current handler; this allows for updates afterwards.
        this.#PopupHandlers[layerId] = htmlGenerator

        return this;
    }

    on(event, callback) {
        this.#Map.on(event, callback);
        return this;
    }

    /**
     * Replaces an existing layer, or adds a new one.
     */
    replaceLayer(layerId, layer) {
        layer = { ...layer, id: layerId };
        if (this.#Map.getLayer(layerId) !== undefined) {
            this.#Map.removeLayer(layerId);
        }
        this.#Map.addLayer({ ...layer, id: layerId });

        return this;
    }

    /**
     * Updates paint properties for a given layer.
     * @param {string} layerId ID of the layer to change
     * @param {string} propertyName paint color property - i.e. 'fill-color'
     * @param {*} propertyValue new value
     * @returns 
     */
    setLayerPaintProperty(layerId, propertyName, propertyValue) {
        this.#Map.setPaintProperty(layerId, propertyName, propertyValue);

        return this;
    }
}
