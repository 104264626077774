
/**
 * Holds metadata used to describe a dataset.
 */
export class DataSetDefinition {
    Name = undefined;
    Description = undefined;
    AvailableFields = undefined;

    #Data = undefined; //Dataset value; lazily initiated.
    #Generator = undefined; //Used to generate the dataset;

    /**
     * 
     * @param {string} name Name of the dataset.
     * @param {string} description Dataset Source.
     * @param {Obj} availableFields Map of property name => display name used for display purposes
     * @param {function} generator function of type ({DataSetDefinition}) => {DataSet} to instantiate and transform the dataset.
     */
    constructor(name, description, availableFields, generator) {
        this.Name = name;
        this.Description = description;

        this.AvailableFields = availableFields || {};

        this.#Generator = generator;
    }

    get Data() {
        if (this.#Data === undefined) {
            this.#Data = this.#Generator(this);
        }
        return this.#Data;
    }
}