/*
 * Builds map controls for changing colour, selection text, etc.
 */

const onChangeHandler = (dropdowns) => (callback) => () => {
    const allVals = Object.fromEntries(Object.entries(dropdowns).map(([key, drop]) => [key, drop.value]));

    return callback(allVals);
}

export const buildMapControls = (propertyToDisplayNameMap, colourBySelector, linkSelector, onSelectSelector, detailTextSelector) => {
    const dropdowns = {
        fill: document.querySelector(colourBySelector),
        popupLink: document.querySelector(linkSelector),
        popupTitle: document.querySelector(onSelectSelector),
        popupDetails: document.querySelector(detailTextSelector),
    };

    return (filters = {}) => {
        //Update HTML lists for each.
        const sortedProps = Object.entries(propertyToDisplayNameMap)
            .sort(([k1, v1], [k2, v2]) => v1 >= v2 ? 1 : -1);

        Object.entries(dropdowns).forEach(([dropName, element]) => {
            let p = sortedProps;
            if (filters.hasOwnProperty(dropName)) p = p.filter(([p, dn]) => filters[dropName](p));

            element.innerHTML = p.map(([prop, display]) => `<option value="${prop}">${display}</option>`).join("");
        });

        return (selectionChangedCallback) => {
            Object.values(dropdowns).forEach(drop => {
                drop.oninput = onChangeHandler(dropdowns)(selectionChangedCallback);
            });

            return (defaultValues) => {
                Object.entries(defaultValues).forEach(([dropName, selectedVal]) => {
                    dropdowns[dropName].selectedIndex = [...dropdowns[dropName].options].findIndex(o => o.value == selectedVal);
                });

                return selectionChangedCallback(defaultValues);
            }
        }
    }
}