/*
 *  Simple Script to pull cached/sanitised copy of Australia's SmartTraveller ('AST') advice into a PlotLy/MapBox map.
 */

import { AstAdviceDataSet } from './modules/data-source-ast.js'
import { CountryGeoJsonDataSet, mergeIntoGeoJson, getGeoPropertyRanges, getGeoPropertiesMatching } from './modules/data-source-geojson.js';
import { anchorManager } from './modules/ui-anchor-management.js';
import { OxCgrtDataSet } from './modules/data-source-oxcgrt.js';
import { GeoJsonMap } from './modules/ui-build-map.js';
import { MapStyle } from './modules/ui-map-style.js';
import { buildMapControls } from './modules/ui-build-mapcontrols.js';
import { buildPropertySearch } from './modules/ui-build-search.js';

const mapLayerId = "geo"; //Doesn't matter what this is; just needs to be unique.
const mapLayerBase = { source: mapLayerId }

const getDefaultMapStyle = () => {
    const style = getComputedStyle(document.documentElement);
    return MapStyle.fillInterpolate(mapLayerBase)(
        [20, 40, 60, 80, 100].map(i => style.getPropertyValue(`--ta-level-${i}`)),
        parseFloat(style.getPropertyValue('--ta-opacity'))
    )
}

const mapStyleOverrides = () => {
    const style = getComputedStyle(document.documentElement);
    return {
        "ast_ta_level": MapStyle.fillInterpolate(mapLayerBase)(
            [20, 40, 60, 80, 100].map(i => style.getPropertyValue(`--ta-level-${i}`)),
            parseFloat(style.getPropertyValue('--ta-opacity'))
        )(20, 100)("ast_ta_level")
    }
};

const defaultHtmlSelector = (newProps) => (props) => {
    return `<h6><a class="noColor" href="${props[newProps.popupLink]}" target="_blank">${props[newProps.popupTitle]}</a></h6><i>Value: ${props[newProps.fill]}</i><br>${props[newProps.popupDetails]}`
}

window.onload = async () => {
    let datasets = [AstAdviceDataSet, CountryGeoJsonDataSet, OxCgrtDataSet];
    let [astData, geoJson, oxCgrt] = await Promise.all(datasets.map(ds => ds.Data.result));

    //Country search
    buildPropertySearch(astData.articles, "country", "#article-template", "#article-details-section", "#country-search", "#article-container", 5);

    //Merge datasets into geoJson.
    geoJson = mergeIntoGeoJson(geoJson, "iso_a3", Object.fromEntries(astData.articles.map(a => [a.ast_cca3, a])), oxCgrt)

    const geoNumericRanges = getGeoPropertyRanges(geoJson); //Determine numeric fields (for map fill) and their map styles.
    const geoUrlFields = getGeoPropertiesMatching(geoJson, /^http/i);

    //By default all properties get a standard style - see above.
    const styles = {
        ...Object.fromEntries(Object.keys(geoNumericRanges).map(k => [k, getDefaultMapStyle()(...geoNumericRanges[k])(k)])),
        ...mapStyleOverrides() //Override with custom styles.
    };

    //Used for our map controls below.
    const displayNames = { ...CountryGeoJsonDataSet.AvailableFields, ...AstAdviceDataSet.AvailableFields, ...OxCgrtDataSet.AvailableFields };

    //Build our map controls.
    const controlsUpdater = buildMapControls
        //Markup-related items (query selectors etc.)
        (displayNames, "#map-colour-by", "#map-link", "#map-onselect-text", "#map-detail-text")
        //Filters for properties. Restrict fill to numeric only; popupDetails to non-numeric (no need to double up)
        ({ 
            "fill": (v) => Object.keys(geoNumericRanges).includes(v), 
            "popupTitle": (v) => !(Object.keys(geoNumericRanges).includes(v)),
            //"popupDetails": (v) => !(Object.keys(geoNumericRanges).includes(v)), // Allow all for now.
            "popupLink": (v) => geoUrlFields.includes(v)
        })
        //Updater; executes when new controls are selected. Here we just update the map & popup.
        ((newProps) => {
            map
                .replaceLayer(mapLayerId, styles[newProps.fill])
                .setLayerPopup(mapLayerId, defaultHtmlSelector(newProps));
        });

    //Now we tell the browser to update controls whenever the hash changes
    window.onhashchange = anchorManager("#description-container")(controlsUpdater);

    //Finally; draw the map.
    const map = new GeoJsonMap('map-container', 'https://api.maptiler.com/maps/1df2d070-56ae-440f-be31-3d11e8cc7ac3/style.json?key=87SBYi5oKiAii4Pg6NHw', [0, 0], 0.81)
        .addSource(mapLayerId, "geojson", geoJson)
        .on("load", () => window.onhashchange()); //Force an update of selected controls on first load.

    //Last Step - Set last updated field 
    document.querySelector("#last-update-field").textContent = astData.last_updated;
};