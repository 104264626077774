/*
 * Dataset based on OxCGRT data - e.g. Government COVID-19 policy & response tracking
 */
import { DataSetDefinition } from "./data-source-meta.js";
import { JsonDataSet } from "./data-manip-JsonDataSet.js";
import { ExpressionField } from "./data-manip-DataSet.js";
import { FieldDescriptions } from "./data-source-oxcgrt-desc.js";


// We work this dataset a little differently; OxCgrt includes ~40+ fields most of whick we don't need to translate.
// Additionally we want to generate a 'description' for each of the discrete numeric fields that maps {int} -> {string} and describes what it means.

// So - let's start with a list of properties we want to keep as-is
const propList = [
    "C1_School closing",
    "C2_Workplace closing",
    "C3_Cancel public events",
    "C4_Restrictions on gatherings",
    "C5_Close public transport",
    "C6_Stay at home requirements",
    "C7_Restrictions on internal movement",
    "C8_International travel controls",
    "ConfirmedCases",
    "ConfirmedDeaths",
    "ContainmentHealthIndex",
    "CountryCode",
    "E1_Income support",
    "E2_Debt/contract relief",
    "E3_Fiscal measures",
    "E4_International support",
    "EconomicSupportIndex",
    "GovernmentResponseIndex",
    "H1_Public information campaigns",
    "H2_Testing policy",
    "H3_Contact tracing",
    "H4_Emergency investment in healthcare",
    "H5_Investment in vaccines",
    "H6_Facial Coverings",
    "H7_Vaccination policy",
    "H8_Protection of elderly people",
    "M1_Wildcard",
    "StringencyIndex",
    "StringencyLegacyIndex",
    "V1_Vaccine Prioritisation (summary)",
    "V2A_Vaccine Availability (summary)",
    "V2B_Vaccine age eligibility/availability age floor (general population summary)",
    "V2C_Vaccine age eligibility/availability age floor (at risk summary)",
    "V2D_Medically/ clinically vulnerable (Non-elderly)",
    "V2E_Education",
    "V2F_Frontline workers  (non healthcare)",
    "V2G_Frontline workers  (healthcare)",
    "V3_Vaccine Financial Support (summary)"
]

//OK, now we take our list of properties and generate our expression fields.
//If a given field is present in FieldDescriptions, we create a second field with suffix " - Description"
const propMap = {
    ...Object.fromEntries(
        propList.map(p => {
            if (FieldDescriptions.hasOwnProperty(p)) {
                return [p, [ExpressionField.renameTo(p), new ExpressionField(`${p} - Description`, (o, v) => FieldDescriptions[p](v))]]
            }
            else return [p, ExpressionField.renameTo(p)]
        })
    ),
    // Date is a known special case
    "Date": new ExpressionField("Last Updated Date", (obj, v) => {
        console.log(v)
        return v.toString().replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3")
    })
}

export const OxCgrtDataSet = new DataSetDefinition(
        "OxCGRT Government COVID-19 Response Data",
        "Sourced from OxCGRT GitHub and includes a number of flags describing different government's response to COVID-19. See the codebook for details on the available fields: https://github.com/OxCGRT/covid-policy-tracker/blob/master/documentation/codebook.md",
        //Display Names - same as propMap keys; just prefixed.
        Object.fromEntries(Object.entries(propMap).flatMap(([k, v]) => {
            //If v is array we need to pull out new field names.
            if (v instanceof Array) return v.map(v2 => [v2.Name, `(OxCGRT) ${v2.Name}`])
            else return [[v.Name, `(OxCGRT) ${v.Name}`]]
        })),
        (meta) => {
            return new JsonDataSet('https://covidtraveller-data.brodieh.net/data/OxCgrt.json')
                .then((data) => {
                    return Object.fromEntries(
                        Object.entries(data)
                            .map(([k, v]) => [k, ExpressionField.translateObject(v, propMap)])
                    )
                })
        }
    );