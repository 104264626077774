/*
 * Provides a method of changing dataset based on form input
 */

const anchorSelectionMap = {
    "#butarethepubsopen": {
        fill: "C4_Restrictions on gatherings",
        popupTitle: "country",
        popupDetails: "C4_Restrictions on gatherings - Description",
        popupLink: "ddg_search"
    },
    "#masks": {
        fill: "H6_Facial Coverings",
        popupTitle: "country",
        popupDetails: "H6_Facial Coverings - Description",
        popupLink: "ddg_search"
    },
    "#workplace": {
        fill: "C2_Workplace closing",
        popupTitle: "country",
        popupDetails: "C2_Workplace closing - Description",
        popupLink: "ddg_search"
    },
    "#events": {
        fill: "C3_Cancel public events",
        popupTitle: "country",
        popupDetails: "C3_Cancel public events - Description",
        popupLink: "ddg_search"
    },
    "#pubtrans": {
        fill: "C5_Close public transport",
        popupTitle: "country",
        popupDetails: "C5_Close public transport - Description",
        popupLink: "ddg_search"
    },
    "#stayathome": {
        fill: "C6_Stay at home requirements",
        popupTitle: "country",
        popupDetails: "C6_Stay at home requirements - Description",
        popupLink: "ddg_search"
    },
    "#internalmovement": {
        fill: "C7_Restrictions on internal movement",
        popupTitle: "country",
        popupDetails: "C7_Restrictions on internal movement - Description",
        popupLink: "ddg_search"
    },
    "#international": {
        fill: "C8_International travel controls",
        popupTitle: "country",
        popupDetails: "C8_International travel controls - Description",
        popupLink: "ddg_search"
    },
    "": { // default
        fill: "ast_ta_level",
        popupTitle: "country",
        popupDetails: "ast_ta_advice",
        popupLink: "ast_link"
    }
}

function getPropertyMapForKey(key) {
    if (anchorSelectionMap.hasOwnProperty(key)) {
        return anchorSelectionMap[key];
    }
    return anchorSelectionMap[""]
}

export const anchorManager = (descriptionContainer) => {
    const cont = document.querySelector(descriptionContainer);
    const allDesc = [...cont.querySelectorAll(":scope > div")];
    const defaultDesc = cont.querySelector(":scope > div[default]");

    return (callback) => {
        return () => {
            //Update desc
            const noHash = location.hash.slice(1);
            const newDesc = allDesc.filter(d => d.id == noHash)[0] || defaultDesc;

            newDesc.style.display = "block";
            allDesc.filter(d => !d.isEqualNode(newDesc)).forEach(d => d.style.display = "none");

            //Exec callback with new properties.
            const newProps = getPropertyMapForKey(location.hash);
            callback(newProps);
        }
    }
}