/*
 * Helper that returns parsed Australian Smart Traveller data.
 * We split this from the main load-map.js for clarity/readability.
 */

import { ExpressionField } from "./data-manip-DataSet.js";
import { JsonDataSet } from "./data-manip-JsonDataSet.js";
import { DataSetDefinition } from "./data-source-meta.js";

const propMap = {
    "link": ExpressionField.renameTo("ast_link"),
    "ta_advice": ExpressionField.renameTo("ast_ta_advice"),
    "ta_level": new ExpressionField("ast_ta_level", (item, level) => {
        let [num, ...denom] = level.split("/").map(s => s.trim());
        return 100 * parseFloat(num) / parseFloat(denom);
    }),
    "published": new ExpressionField("ast_published", (obj, dateVal) => (new Date(dateVal)).toLocaleDateString()),
    "description": ExpressionField.renameTo("ast_description"),
    "country": ExpressionField.renameTo("country"),
    "cca3": ExpressionField.renameTo("ast_cca3")
}

export const AstAdviceDataSet = new DataSetDefinition(
    "Australian Smart Traveller (AST) Advice",
    "Data sourced from the Smart Traveller RSS feed. Data latency is at most four hours behind the AST page",
    //Display names - used for e.g. map controls
    {
        "ast_link": "(AST) Advice Link",
        "ast_ta_advice": "(AST) Advice Level - Description",
        "ast_ta_level": "(AST) Advice Level",
        "ast_published": "(AST) Published Date",
        "ast_description": "(AST) Status Description",
        "country": "Country Name",
    },
    //Generator used to create the dataset.
    (meta) => (new JsonDataSet("https://covidtraveller-data.brodieh.net/data/AstAdvice.json"))
        .then((data) => {
            data.last_updated = new Date(data.last_updated).toLocaleString()
            return data
        })
        .translateArrayFields(propMap, "articles")
)