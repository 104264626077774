/*
 * Module to pull data from GeoJSON.xyz and filter to sane properties etc.
 */
import { ExpressionField } from "./data-manip-DataSet.js";
import { JsonDataSet } from "./data-manip-JsonDataSet.js";
import { DataSetDefinition } from "./data-source-meta.js";
import { splitOnce } from "./utils-object.js";

const propMap = {
    iso_a3: ExpressionField.renameTo("iso_a3"),
    name: [
        ExpressionField.renameTo("country"),
        new ExpressionField("ddg_search", (i, v) => `https://duckduckgo.com/?kae=d&q=${v} COVID-19 Policy`)
    ],
    pop_est: ExpressionField.renameTo("population_estimate"),
    gdp_md_est: ExpressionField.renameTo("gdp_estimate_millions"),
    lastcensus: ExpressionField.renameTo("last_census"),
    economy: [
        new ExpressionField("economy_rank", (item, val) => parseInt(0.5 + (100 * (parseInt(splitOnce(val, '.')[0]) / 7.0)))),
        new ExpressionField("economy_description", (item, val) => splitOnce(val, '.')[1].toString().trim()),
    ],
    income_grp: [
        new ExpressionField("wb_income_rank", (item, val) => 100 * (parseInt(splitOnce(val, '.')[0]) / 5.0)),
        new ExpressionField("wb_income_description", (item, val) => splitOnce(val, '.')[1].toString().trim())
    ]
}

export const CountryGeoJsonDataSet = new DataSetDefinition(
    "GeoJSON.xyz Country Data",
    "Country GeoJSON data from GeoJSON.xyz and based on Natural Earth dataset. Includes demographic info.",
    //Display Names
    {
        iso_a3: "(GeoJSON) ISO A3 Code",
        country: "Country Name",
        population_estimate: "(GeoJSON) Population Estimate",
        gdp_estimate_millions: "(GeoJSON) GDP Estimate (US millions)",
        last_census: "(GeoJSON) Last Census",
        economy_rank: "(GeoJSON) Economy Rank",
        economy_description: "(GeoJSON) Economy Rank - Description",
        wb_income_rank: "(GeoJSON) World Bank Income Rank",
        wb_income_description: "(GeoJSON) World Bank Income Rank - Description",
        ddg_search: "(DuckDuckGo) COVID-19 policy search"
    },
    (meta) => {
        return (new JsonDataSet('https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_0_countries.geojson'))
            .then((json) => {
                json.features.forEach(feature => {
                    feature.properties = ExpressionField.translateObject(feature.properties, propMap);
                });
                return json;
            });
    }
)

/**
 * Simplifies merging lookup dicts into GeoJSON properties for mapping.
 * @param {Object} geoJson GeoJSON object
 * @param {string} geoJsonKey name of the property to use as key
 * @param  {...Object} lookupDicts an Array of Objects containing mappings of KeyVal => Properties.
 * @returns new GeoJSON with lookup Properties merged into their respective feature.
 */
export function mergeIntoGeoJson(geoJson, geoJsonKey, ...lookupDicts) {
    geoJson.features.forEach((feature) => {
        feature.properties = lookupDicts.reduce((acc, next) => {
            return {...acc, ...next[feature.properties[geoJsonKey]]};
        }, feature.properties);
    });

    return geoJson;
}

/**
 * Returns a map of string => [min, max] representing the range of numeric properties.
 */
export function getGeoPropertyRanges(geoJson) {
    const keys = [...new Set(geoJson.features.flatMap(f => Object.keys(f.properties).filter(p => typeof(f.properties[p]) === "number" && Number.isFinite(f.properties[p]))))]

    return Object.fromEntries(keys.map(k => {
        const vals = geoJson.features.map(f => f.properties[k]).filter(v => v !== undefined)
        return [k, [Math.min(...vals), Math.max(...vals)]]
    }));
}

/**
 * Returns a list of properties that match a given regex
 */
export function getGeoPropertiesMatching(geoJson, regex) {
    return [...new Set(geoJson.features.flatMap(f => Object.entries(f.properties).filter(([k,v]) => regex.test(v)).map(([k]) => k)))];
}