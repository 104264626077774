/*
 * Provides classes for streamlining data manipulation
 */

/**
 * Describes a field by name and lambda expression that calculates it's value
 */
export class ExpressionField {
    Name = undefined;
    Expression = undefined;

    /**
     * Instantiates the ExpressionField
     * @param {string} name Name of the field
     * @param {function} expression Function of type (object, currentValue) -> {any} that calculates field value. Full object included for easy access to other fields.
     */
    constructor(name, expression) {
        this.Name = name;
        this.Expression = expression;
    }

    /**
     * Expression that returns the current value as-is - use it as the Expression value on your ExpressionField instance.
     */
    static returnAsIsExpr(object, value) {
        return value;
    }

    /**
     * Returns a new ExpressionField that does not modify the value but can (optionally) rename the field.
     */
    static renameTo(newName) {
        return new ExpressionField(newName, this.returnAsIsExpr);
    }

    /**
     * Helper that takes an object and a map of propName => ExpressionField (or Array<ExpressionField) and builds a new object that:
     *  - Only contains properties represented by ExpressionField.name
     *  - Values are set to ExpressionField.expression(object, object[propName])
     * @param {Object} object 
     * @param {Object} map Map of {String} => {ExpressionField} (or Array<ExpressionField)
     * @returns The translated object.
     */
    static translateObject(object, map) {
        const keys = Object.keys(map);
        return Object.fromEntries(
            Object.entries(object)
                .filter(([propName]) => keys.includes(propName)) //Filter out undesirables
                .flatMap(([propName, propValue]) => { //FlatMap ExpressionFields to new values; flat allows for name => {Array<ExpressionField>} entires.
                    const field = map[propName];
                    if (field instanceof ExpressionField) {
                        return [[field.Name, field.Expression(object, propValue)]]; //Nested array required for flatMap.
                    }
                    // the .some() ensures all fieldMap collection items are of type ExpressionField.
                    else if (field instanceof Array && !field.some(f => !(f instanceof ExpressionField))) {
                        return field.map(newField => [newField.Name, newField.Expression(object, propValue)]);
                    }
                    else {
                        throw new Error("fieldMap must only contain (arrays of) ExpressionFields.");
                    }
                })
        );
    }
}

/**
 * Base DataSet class. That wraps a promise for method chaining and includes helpers for simple data manipulation.
 * Derived classes should overide constructor and run super(promise). The promise should return a single data object when complete.
 */
export class DataSet {
    #promise = undefined;

    constructor(promise) {
        if (!promise) throw new Error("DataSet: Promise must be valid and not null");
        this.#promise = promise;
    }

    /**
     * Gets the value of the promise
     */
    get result() {
        return this.#promise;
    }

    /**
     * Invokes #promise.then with support for DataSet method chaining.
     * @param {function} callback Callback of type (data) => {Any} to invoke.
     * @returns this (method chaining).
     */
    then(callback) {
        this.#promise = this.#promise.then(callback);
        return this;
    }

    /**
     * Returns a tuple of at this location; useful if you want to re-use datasets.
    */
    fork() {
        return new DataSet(this.#promise);
    }

    /**
     * Assumes the data is a collection and runs reduce(accumulatorFunc) on the result
     * @param {function} accumulatorFunc function passed to reduce()
     * @returns this (method chaining)
     */
    reduce(accumulatorFunc, initial) {
        return this.then((values) => values.reduce(accumulatorFunc, initial));
    }

    /**
     * Promotes a field and ignores everything else in the dataset. E.g., promote("MyField") will allow subsequent calls to access MyField directly.
     * @param {*} fieldName Name of field to promote.
     * @returns this (method chaining)
     */
    promote(fieldName) {
        return this.then((data) => data[fieldName]);
    }

    /**
     * Translates an array into an object of key-value pairs (e.g. mapping [keyFieldValue] => [arrayItem]) given a key field.
     * @param {string} keyFieldName Name of the key field that will become keys on the resulting object.
     * @returns this (method chaining)
     */
    arrayToObject(keyFieldName) {
        return this.reduce((acc, next) => {
            if (next[keyFieldName]) acc[next[keyFieldName]] = next;
            return acc;
        }, {});
    }

    /**
     * Renames fields on items in an array and generates new fields from definition.
     * @param {*} fieldMap a map of (existingPropteryName) => {FieldExpression} or (existingPropertyName) => {Array<FieldExpression}. Each array element becomes a new field.
     * @param {*} collectionField Optional, the name of the collection property on the data that will be modified. E.g. "ArrayProp" will modify data.ArrayProp. If not set the entire data object is evaluated / modified.
     * @returns this (for method chaining)
     */
    translateArrayFields(fieldMap, collectionField = undefined) {
        return this.then((data) => {
            const keys = Object.keys(fieldMap);

            //Translate func - used for map() below.
            const translate = (i) => ExpressionField.translateObject(i, fieldMap);

            //Modifiy the collection fields and return data.
            if (collectionField) data[collectionField] = data[collectionField].map(translate);
            else data = data.map(translate);

            return data;
        });
    }
}