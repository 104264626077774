/*
 * Utilities for dealing with objects - string template replacement, smart(er) merging, etc.
 */

/**
 * Re-hydrates a template string by replacing ${prop} with sourceObject[prop]
 * @param {String} template Template string
 * @param {Object} sourceObject Source object
 * @returns New string with ${property} replaced with sourceObject[property] (if property exists in sourceObject)
 */
export function replaceStringWithProperties(template, sourceObject) {
    return template.replace(/\$\{([\w\d_-]+)\}/g, (match, p1) => sourceObject.hasOwnProperty(p1) ? sourceObject[p1] : match)
}

/**
 * Merges any number of objects into baseObject by evaluating shouldMerge(m) for m in mergeObjects
 * @param {Object} baseObject  
 * @param {function} shouldMerge Function that takes one argument (the current mergeObject) and returns whether the object should merge into baseObject.
 * @param  {Object[]} mergeObjects Array of objects to merge. mergePropertyNames[i] is used as the join property (compared to lookupProperyName on baseObject)
 */
export function mergeWithLookup(baseObject, shouldMerge, mergeObjects) {
    return mergeObjects.filter(i => shouldMerge(i)).reduce( (acc, next) => {
        return {...acc, ...next}
    }, baseObject);
}

/**
 * Splits a string once and only once
 * @param {*} string String to split
 * @param {*} on What to split on
 * @returns Array of strings [before, after]
 */
export function splitOnce(str, on) {
    let [first, ...rest] = str.split(on);
    return [first, rest];
}